import { createSlice, combineReducers } from "@reduxjs/toolkit";
import genericService from "../services/generic.service";
  

const initialState = {
    basket: {
      items: [],
      predniPotisk: {
        horniNapis: undefined,
        potisk: undefined,
        dolniPredniNapisText: undefined,
        barvaPotisku: undefined,
        specialniUmisteni: false,
        predniPotiskVelikostA2: false,
        vlastniObrazek: undefined,
        vlastniObrazekUrl: undefined,
        vlastniObrazekSet: false,
        obrazekPotisku: undefined,
        seznamJmen: undefined,
        ownTextToggle: false,
      },
      zadniPotisk: {
        specialniUmisteni: false,
        zadniPotiskVelikostA2: false,
        vlastniObrazek: undefined,
        vlastniObrazekUrl: undefined,
        vlastniObrazekSet: false,
        ownTextToggle: false,
      },
      sleeves: {
        leftSleeveText: "",
        rightSleeveText: "",
        leftSleeveNickNames: false,
        rightSleeveNickNames: false,
      },
      ribbons: {
        quantity: 10,
        price: 0,
        ribbonsThumbChosen: false,
        printColorPrice: 0,
        nicks: {},
        addNicks: false,
        printColorLabel: undefined,
        ribbonsExists: false,
        chosen: false,
        ribbonsText: 'MATURANT',
        printColorId: undefined,
        printColorCode: undefined,
        pismo: undefined,
        extrasPriceNicks: 0
      },
      lists: {
        chosen: false,
        quantity: 10,
        listThumbChosen: false,
        listsExist: false
      },
      school: {},
      students: {
        names: [],
        nicks: [],
        mappedNames: {},
      },
      priplatky: [],
      priplatkyCelkem: 0,
      contact: {
        firstname: undefined,
        lastname: undefined,
        phone: undefined,
        email: undefined,
      },
      address: {
        company: undefined,
        street: undefined,
        city: undefined,
        zip: undefined,
      },
      note: {
        note: undefined,
      },
    },
    itemDetails: [],
    itemPriceDetail: [],
    data: {
      textilData: [],
      ribbonsData: [],
      tvarSeznamuJmenData: [],
      horniNapisData: [],
      barvaPotiskuData: [],
      obrazkyPotiskuData: [],
      dolniPismoData: [],
      paramsData: [],
      paramsDataSize: 0
    },
    totalQuantity: 0,
    totalPrice: 0,
    changed: false,
    allProducts: [],
    horniNapisActivePage: 0,
    horniNapisChosenPage: 0,
    horniNapisChosenPageShift: 0,
    obrazekPotiskuActivePage: 0,
    obrazekPotiskuChosenPage: 0,
    obrazekPotiskuChosenPageShift: 0,
    displayPopupObrazekPotisku: false,
    displayPopupHorniNapis: false,
    displayPopupTvarSeznamuJmen: false,
    popup: {},
    horniNapisContentStyle: {},
    horniNapisShift: 0,
    horniNapisWidth: 100000,
    obrazekPotiskuShift: 0,
    zadniPotiskOwnTextToggle: false,
    activeThumb: undefined,
    activeStep: "StepOne",
    activeStepNumber: 1,
    showWarning: false,
    lastValidStep: 1,
    stepOneValid: false,
    stepTwoValid: false,
    stepTwoFirstLoad: true,
    stepThreeValid: false,
    stepThreeFirstLoad: true,
    stepFourValid: false,
    checkForExtras: false,
    showFirstMessage: false,
    minimumVersion: 1
  
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    replaceCart(state, action) {
      state.totalQuantity = action.payload.totalQuantity;
      state.basket.items = action.payload.items;
    },
    fetchProducts(state, action) {
      state.allProducts = action.payload.allProducts;
    },
    addItemToCart(state, action) {
      if (state.basket === undefined) {
        initialState()
        state.basket.items = [];
      }
      const newItem = action.payload;
      let existingItem = undefined;
      try {
        existingItem = state.basket.items.find((item) => item.id === newItem.id);
      } catch (e) { }
      const itemId = newItem.id.split("#");
      let quantity = 0;
      if (!isNaN(parseInt(newItem.quantity))) {
        quantity = parseInt(newItem.quantity);
      }
      state.changed = true;
      if (!existingItem) {
        if (state?.basket?.items === undefined) {
          state.basket.items = [];
        }
        state.basket.items.push({
          id: newItem.id,
          price: newItem.price,
          quantity: newItem.quantity,
          totalPrice: newItem.price,
          title: newItem.title,
          textil: itemId[0],
          size: newItem.size,
          sizeId: newItem.sizeId,
          material: newItem.material,
          gramaz: newItem.gramaz,
          colorId: newItem.colorId,
          colorName: newItem.colorName,
        });
      } else {
        existingItem.quantity = quantity;
        existingItem.totalPrice = existingItem.quantity * existingItem.price;
      }
      const validItems = state.basket.items.filter((e) => e.quantity > 0);
      state.basket.items = validItems;
      state.totalQuantity = 0;
      state.totalPrice = 0;
      let itemCount = 0;
      state.basket.items.forEach(function (element) {
        state.totalQuantity += parseInt(element.quantity);
        state.totalPrice += parseInt(element.quantity) * parseInt(element.price);
        itemCount++;
      });
      if (state.totalQuantity >= 10) {
        state.stepOneValid = true;
        state.lastValidStep = 2;
      } else {
        state.stepOneValid = false;
        state.lastValidStep = 1;
      }
      if (itemCount === 1) state.activeThumb = `shirtBasketThumb#${itemId[0]}#${newItem.colorId}`;
    },
    addPredniPotiskToTheCart(state, action) {
      const id = action.payload.id;
      if (action.payload.order !== undefined) {
        const order = parseInt(action.payload.order) + 1;
        state.horniNapisActivePage = parseInt(order / 4);
        state.horniNapisShift = state.horniNapisActivePage * -142 * 4;
      }
      let horniNapisCode = "";
      horniNapisCode = id.split("|");
      horniNapisCode = horniNapisCode[1] + "|" + horniNapisCode[2] + "|" + horniNapisCode[3];
      if (state.predniPotisk === "undefined") {
        state.predniPotisk = {};
      } else {
        state.basket.predniPotisk.horniNapis = horniNapisCode;
      }
      if (action.payload.closeWindow === true) state.displayPopupHorniNapis = false;
      state.horniNapisChosenPage = state.horniNapisActivePage;
      state.horniNapisChosenPageShift = state.horniNapisShift;
    },
    addObrazekPotiskuToTheCart(state, action) {
      const oneImageWidth = 190;
      const imagesPerPage = 3;


      const id = action.payload.id;

      if (action.payload.order !== undefined) {
        const order = parseInt(action.payload.order) + 1;
        state.obrazekPotiskuActivePage = parseInt(order / imagesPerPage);
        state.obrazekPotiskuShift = state.obrazekPotiskuActivePage * -oneImageWidth * imagesPerPage;
      }

      let obrazekPotiskuCode = "";
      obrazekPotiskuCode = id.split("|");
      obrazekPotiskuCode = obrazekPotiskuCode[1] + "|" + obrazekPotiskuCode[2] + "|" + obrazekPotiskuCode[3];
      if (state.basket.predniPotisk === "undefined") {
        state.basket.predniPotisk = {
          horniNapis: 0,
          potisk: 0,
          dolniPredniNapisText: "",
          barvaPotisku: "",
          specialniUmisteni: 0,
          predniPotiskVelikostA2: 0,
          vlastniObrazek: "",
          obrazekPotisku: 0,
        };
      } else {
        state.basket.predniPotisk.obrazekPotisku = obrazekPotiskuCode;
      }
      if (action.payload.closeWindow === true) state.displayPopupObrazekPotisku = false;

      state.obrazekPotiskuChosenPage = state.obrazekPotiskuActivePage;
      state.obrazekPotiskuChosenPageShift = state.obrazekPotiskuShift;
    },
    addBarvaPotiskToTheCart(state, action) {
      state.showLoading = true;
      const id = action.payload.id;
      let barvaPotiskuCode = "";
      const barvaPotiskuCodeSplit = id.split("|");
      if (barvaPotiskuCodeSplit[1] === undefined) {
        state.showLoading = false;
        return
      }
      barvaPotiskuCode = barvaPotiskuCodeSplit[1] + "|" + barvaPotiskuCodeSplit[2] + "|" + barvaPotiskuCodeSplit[3];
      if (state.predniPotisk === "undefined") {
        state.predniPotisk = {
          horniNapis: 0,
          potisk: 0,
          dolniPredniNapisText: "",
          barvaPotisku: "",
          specialniUmisteni: 0,
          predniPotiskVelikostA2: 0,
          vlastniObrazek: "",
          obrazekPotisku: 0,
        };
      } else {
        state.basket.predniPotisk.barvaPotisku = barvaPotiskuCode;
        state.basket.predniPotisk.colorLabel = barvaPotiskuCodeSplit[4];
      }

      switch (state.activeStep) {
        case "TWO":
          state.stepTwoValid = genericService.validateStepTwo(state.basket);
          break;
        default:
      }
    },
    removeTextilFromCart(state, action) {
      const textilId = action.payload.textilId;
      const colorId = action.payload.colorId;
      const foundItem = state.basket.items.filter((e) => e.textil === textilId && parseInt(e.colorId) === parseInt(colorId));
      foundItem.forEach(function (element) {
        const index = state.basket.items.findIndex((e) => e.id === element.id);
        state.basket.items.splice(index, 1);
      });

      for (var key in state.basket.students.mappedNames) if (key.startsWith(textilId + "#" + colorId)) delete state.basket.students.mappedNames[key];

      const validItems = state.basket.items.filter((e) => e.quantity > 0);
      state.basket.items = validItems;

      state.totalQuantity = 0;
      state.basket.items.forEach(function (element) {
        state.totalQuantity += parseInt(element.quantity);
      });
      state.changed = true;


      if (state.totalQuantity === 0) state.activeThumb = undefined;
      else if (state.activeThumb === `shirtBasketThumb#${textilId}#${colorId}`) {
        const firstItem = state.basket.items[0];
        state.activeThumb = `shirtBasketThumb#${firstItem.textil}#${firstItem.colorId}`;
      }
    },
    removeRibbonFromCart(state, action) {
      state.basket.ribbons.ribbonsThumbChosen = false;
      state.basket.ribbons.id = undefined;
      state.basket.ribbons.quantity = undefined;
      state.basket.ribbons.chosen = false;
      state.basket.ribbons.price = undefined;
      state.basket.ribbons.printColorId = undefined;
      state.basket.ribbons.printColorCode = undefined;
      state.basket.ribbons.ribbonsText = "MATURANT";
      state.basket.ribbons.ribbonsExists = false
      state.basket.predniPotisk.ribbons = undefined;
      state.basket.predniPotisk.serpaDolniPismo = undefined;
      state.basket.ribbons.pismo = undefined;

    },
    removeListFromCart(state, action) {
      state.basket.predniPotisk.lists = undefined;
      state.basket.lists.listThumbChosen = false;
      state.basket.lists.id = undefined;
      state.basket.lists.listId = undefined;
      state.basket.lists.quantity = undefined;
      state.basket.lists.chosen = false;
    },
    setHorniNapisActivePage(state, action) {
      state.data.horniNapisDataSize = state.data.horniNapisData.length;
      let pageNo = 0;
      if (isNaN(parseInt(action.payload.page))) {
        pageNo = 0;
      } else {
        pageNo = parseInt(action.payload.page);
      }
      state.horniNapisActivePage = parseInt(pageNo);
      state.horniNapisShift = pageNo * -142 * 4;
      state.horniNapisWidth = (state.data.horniNapisDataSize + 1) * 142;
    },
    setObrazekPotiskuActivePage(state, action) {
      let pageNo = 0;
      if (isNaN(parseInt(action.payload.page))) {
        pageNo = 0;
      } else {
        pageNo = parseInt(action.payload.page);
      }
      state.obrazekPotiskuActivePage = pageNo;
    },
    setActiveThumb(state, action) {
      if (action.payload.id !== state.activeThumb)
        state.showLoading = true;
      state.basket.ribbons.ribbonsThumbChosen = false;
      state.basket.lists.listThumbChosen = false;
      state.activeThumb = action.payload.id;
    },
    setRibbonsThumbChosen(state, action) {
      state.activeThumb = undefined;
      state.basket.ribbons.ribbonsThumbChosen = true;
      state.basket.lists.listThumbChosen = false;
    },
    setListsThumbChosen(state, action) {
      state.activeThumb = undefined;
      state.basket.lists.listThumbChosen = true;
      state.basket.ribbons.ribbonsThumbChosen = false;
    },
    addA2Option(state, action) {
      const sideType = action.payload.sideType;
      state.basket[sideType][sideType + "VelikostA2"] = action.payload.value === "true";
    },
    addSpecialPlacement(state, action) {
      const sideType = action.payload.sideType;
      state.basket[sideType].specialniUmisteni = action.payload.value === "true";
    },
    setDolniPredniNapisText(state, action) {
      state.basket.predniPotisk.dolniPredniNapisText = action.payload.value;
    },
    setVlastniObrazek(state, action) {
      const sideType = action.payload.sideType;
      state.basket[sideType].vlastniObrazek = action.payload.file;
      state.basket[sideType].vlastniObrazekSet = true;
      state.basket[sideType].vlastniObrazekUrl = action.payload.url;
      state.basket[sideType].vlastniObrazekfileType = action.payload.fileType;
    },
    deleteVlastniObrazek(state, action) {
      const sideType = action.payload.sideType;
      state.basket[sideType].vlastniObrazek = "";
      state.basket[sideType].vlastniObrazekSet = false;
      state.basket[sideType].vlastniObrazekUrl = "";
    },
    openPopUpObrazekPotisku(state, action) {
      state.displayPopupObrazekPotisku = true;
    },
    openPopUp(state, action) {
      state.popup = {};
      state.popup.displayPopup = true;
      state.popup.popupType = action.payload.popupType;
      state.popup.popupDataType = action.payload?.popupDataType;
      state.popup.popupLabel = action.payload.popupLabel;
      state.popup.popupId = action.payload.popupId;
      state.popup.popupUrl = action.payload.popupUrl;
      state.popup.popupItemsPerPage = action.payload.popupItemsPerPage;
      state.popup.popupOneImageWidth = action.payload.popupOneImageWidth;
    },
    closePopUp(state, action) {
      state.popup.displayPopup = false;
    },
    closePopUpObrazekPotisku(state, action) {
      state.displayPopupObrazekPotisku = false;
    },
    openPopUpHorniNapis(state, action) {
      state.displayPopupHorniNapis = true;
    },
    closePopUpHorniNapis(state, action) {
      state.displayPopupHorniNapis = false;
    },
    setHorniNapisContentStyle(state, action) {
      state.horniNapisContentStyle = action.payload.contentStyle;
    },
    setTvarSeznamuJmenData(state, action) {
      state.data.tvarSeznamuJmenData = action.payload.tvarSeznamuJmenData;
    },
    setData(state, action) {
      switch (action.payload.type) {
        case "HORNI_NAPIS":
          state.data.horniNapisData = action.payload.data;
          state.data.horniNapisDataSize = action.payload.length;
          break;
        case "DOLNI_PISMO":
          state.data.dolniPismoData = action.payload.data;
          state.data.dolniPismoDataSize = action.payload.data.length;
          break;
        case "BARVA_POTISKU":
          state.data.barvaPotiskuData = action.payload.data;
          state.data.barvaPotiskuDataSize = action.payload.data.length;
          break;
        case "OBRAZKY_POTISKU":
          state.data.obrazekPotiskuData = action.payload.data;
          state.data.obrazekPotiskuDataSize = action.payload.data.length;
          break;
        case "SEZNAM_JMEN":
          state.data.tvarSeznamuJmenData = action.payload.data;
          state.data.tvarSeznamuJmenDataSize = action.payload.data.length;
          break;
        case "RIBBONS":
          state.data.ribbonsData = action.payload.data;
          state.data.ribbonId = action.payload.id;
          state.data.ribbonsDataSize = action.payload.data.length;
          break;
        case "PARAMS":
          try {
            state.data.paramsData = action.payload.data;
            state.data.paramsDataSize = action.payload.length;
          } catch (error) {

          }
          break;
        default:
      }
    },
    setItemTypeActivePage(state, action) {
      const itemType = action.payload.data.itemType;
      const dataType = action.payload.data.dataType;
      const oneImageWidth = action.payload.data.oneImageWidth;
      const itemsPerPage = action.payload.data.itemsPerPage;
      const page = action.payload.data.page;
      state.data[dataType + "DataSize"] = state.data[dataType + "Data"]?.length;
      let pageNo = 0;
      if (isNaN(parseInt(page))) {
        pageNo = 0;
      } else {
        pageNo = parseInt(page);
      }
      state[itemType + "ActivePage"] = parseInt(pageNo);
      state[itemType + "Shift"] = pageNo * -oneImageWidth * itemsPerPage;
      state[itemType + "Width"] = (state.data[itemType + "DataSize"] + 1) * oneImageWidth;
    },
    addPrintItemToTheCart(state, action) {


      state.showLoading = true;
      const dataType = action.payload.dataType;
      const oneImageWidth = action.payload.oneImageWidth;
      const imagesPerPage = action.payload.itemsPerPage;
      const id = action.payload.id;
      const itemType = action.payload.itemType;
      const cartItemCodeArray = id.split("|");
      const cartItemCode = cartItemCodeArray[1] + "|" + cartItemCodeArray[2] + "|" + cartItemCodeArray[3];
      const order = parseInt(action.payload.order, 10);

      if (parseInt(action.payload.order, 10) >= 0) {
        const cartItemOrder = parseInt(action.payload.order) + 1;
        state[itemType + "ActivePage"] = parseInt(cartItemOrder / imagesPerPage);
        state[itemType + "Shift"] = state[itemType + "ActivePage"] * -oneImageWidth * imagesPerPage;
      }
      switch (itemType) {
        case "tvarSeznamuJmen":
          state.basket.zadniPotisk.tvarSeznamuJmen = cartItemCode;
          break;
        case "ribbons":
          const ribbonData = genericService.getRibbonsPrice(state.basket, state.data)
          state.basket.ribbons.id = cartItemCode;
          state.basket.ribbons.chosen = true;
          state.showLoading = false;
          if (state.basket.ribbons.quantity === undefined) {
            state.basket.ribbons.quantity = 10;
          }
          state.basket.ribbons.colorData = action.payload.colorData
          state.basket.ribbons.price = ribbonData.ribbonItemPrice
          state.basket.ribbons.ribbonsExists = true
          break;
        case "lists":
          state.basket.lists.listId = cartItemCode;
          state.basket.lists.chosen = true;
          state.showLoading = false;
          state.basket.lists.price = action.payload.price
          state.basket.lists.listsExist = true
          break;
        case "listText":
          state.basket.lists.listTextId = cartItemCode;
          state.showLoading = false;
          break;
        case "dolniPismo":
          state.showLoading = false;
          break;
        case "serpaDolniPismo":
          state.showLoading = false;
          state.basket.ribbons.pismo = id
          break;
        default:
      }
      if (state.basket.predniPotisk === "undefined") {
        state.basket.predniPotisk = {};
      } else {
        state.basket.predniPotisk[itemType] = cartItemCode;
      }
      if (action.payload.closeWindow === true) state.displayPopup = false;
      state[itemType + "ChosenPage"] = state[itemType + "ActivePage"];
      state[itemType + "ChosenPageShift"] = state[itemType + "Shift"];
      state.popup.displayPopup = false;

      switch (state.activeStep) {
        case "TWO":
          state.stepTwoValid = genericService.validateStepTwo(state.basket);
          break;
        case "THREE":
          state.stepThreeValid = genericService.validateStepThree(state.basket);
          break;
        case "FIVE":
          state.stepFourValid = genericService.validateStepFive(state.basket);
        default:
      }
    },
    resetStore: () => initialState,
    setSchoolInfoData(state, action) {
      state.basket.school[action.payload.id] = action.payload.value;
    },
    setSchoolNamesData(state, action) {
      const id = action.payload.id;
      const idSplit = id.split("#");
      state.basket.students.names[parseInt(idSplit[1] - 1)] = { name: action.payload.value };
    },
    setSchoolNickNamesData(state, action) {
      const id = action.payload.id;
      const idSplit = id.split("#");
      state.basket.students.nicks[parseInt(idSplit[1])] = { name: action.payload.value };
    },
    toggleOwnText(state, action) {
      const itemType = action.payload.sideType;
      state.basket[action.payload.sideType].ownTextToggle = action.payload.checked;
    },
    toggleListsOwnText(state, action) {
      state.basket.lists.ownTextToggle = action.payload.checked;
    },
    setOwnText(state, action) {
      state.basket[action.payload.sideType].ownText = action.payload.ownText;
    },
    setListsOwnText(state, action) {
      state.basket.lists.ownText = action.payload.ownText;
    },
    loadingFinished(state, action) {
      state.showLoading = false;
    },
    setActiveStep(state, action) {
      state.activeStep = action.payload.step;
      switch (action.payload.step) {
        case "TWO":
          state.stepTwoFirstLoad = false;
          break;
        case "THREE":
          state.stepThreeFirstLoad = false;
          break;
        default:
      }
    },
    setPriplatky(state, action) {
      state.basket.priplatky = action?.payload?.priplatky;
      state.basket.priplatkyCelkem = action?.payload?.priplatkyCelkem;
      state.basket.priplatkyRukavCelkem = action?.payload?.priplatkyRukavCelkem;
    },
    setRibbonsPrintColor(state, action) {
      const id = action.payload.id;
      const price = action.payload.price;
      let barvaPotiskuCode = "";
      barvaPotiskuCode = id.split("|");
      state.basket.ribbons.printColorId = barvaPotiskuCode[3];
      state.basket.ribbons.printColorCode = barvaPotiskuCode[2];
      state.basket.ribbons.printColorPrice = parseInt(price);
      state.basket.ribbons.printColorLabel = barvaPotiskuCode[4];
      state.basket.ribbons.extrasPriceNicks = parseInt(price) + 70
    },
    setListsCount(state, action) {
      state.basket.lists.quantity = action.payload.quantity;
      state.showLoading = false;
    },
    setRibbonsCount(state, action) {
      state.basket.ribbons.quantity = action.payload.quantity;
      state.showLoading = false;
    },
    setWarning(state, action) {
      state.warningMessage = action.payload.message;
      state.showWarning = true;
    },
    hideWarning(state, action) {
      state.showWarning = false;
    },
    setSleeveText(state, action) {
      const side = action.payload.side;
      const text = action.payload.text;
      switch (side) {
        case "left":
          state.basket.sleeves.leftSleeveText = text;
          break;
        case "right":
          state.basket.sleeves.rightSleeveText = text;
          break;
        default:
      }
    },
    setSleeveNickNames(state, action) {
      const side = action.payload.side;
      const status = action.payload.status;
      switch (side) {
        case "left":
          state.basket.sleeves.leftSleeveNickNames = status;
          break;
        case "right":
          state.basket.sleeves.rightSleeveNickNames = status;
          break;
        default:
      }
    },
    setOrderDetails(state, action) {
      state.basket[action.payload.type][action.payload.subtype] = action.payload.value;
    },
    addNameToTextil(state, action) {
      const textilId = action.payload.textilId;
      if (typeof textilId !== undefined) {
        let existingItem = undefined;
        try {
           state.basket.students.mappedNames.find((mappedName) => mappedName.key === textilId);
        } catch (e) { }
        state.basket.students.mappedNames[textilId] = action.payload.event;
        let existingTextilItem = state.basket.items.find((item) => item.id === textilId);
        existingTextilItem.nickNames = action.payload.event;
      }
    },
    removeNameFromTextil(state, action) {
      const textilId = action.payload.textilId;
      delete state.basket.students.mappedNames[textilId];
    },
    setRibbonsText(state, action) {
      state.basket.ribbons.ribbonsText = action.payload.value;
    },
    setRibbonsNick(state, action) {
      const id = action.payload.id;
      const idSplit = id.split("#");
      state.basket.ribbons.nicks[parseInt(idSplit[1] - 1)] = action.payload.value;
    },
    toggleRibbonsNicks(state, action) {
      state.basket.ribbons.addNicks = action.payload.checked;
    },
    submitOrder(state, action) {
      state.showLoading = true;
    },
    orderSubmitted(state, action) {
      state.showLoading = false;
    },
    setCheckForExtras(state, action) {
      state.checkForExtras = true
    },
    setShowFirstMessage(state, action) {
      state.showFirstMessage = true
    }
  },
});

export const cartActions = cartSlice.actions;

export default cartSlice;
